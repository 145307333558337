<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import Avatar from "@/components/widgets/avatar";

export default {
  locales: {
    pt: {
      'My Account': 'Minha Conta',
      'Username': 'Usuário',
      'Name': 'Nome',
      'Cellphone': 'Celular',
      'Password': 'Senha',
    },
    es: {
      'My Account': 'Mi Cuenta',
      'Username': 'Nombre de Usuario',
      'Name': 'Nombre',
      'Cellphone': 'Celular',
    }
  },
  components: {
    Layout,
    Avatar
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('My Account') }}</h4>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <Avatar></Avatar>
              </div>
              <div class="col-md-8 pt-md-5">
                <div class="row pt-3 pb-0 pl-3 pr-3">
                  <div class="col-md-6 text-center-sm">
                    <p class="mb-0 text-uppercase font-weight-normal card-title-desc">{{ t('Username') }}</p>
                    <p class="mb-3 font-weight-normal notranslate">{{ account.user.username }}</p>
                  </div>
                  <div class="col-md-6 text-center-sm">
                    <p class="mb-0 text-uppercase font-weight-normal card-title-desc">{{ t('Name') }}</p>
                    <p class="mb-3 font-weight-normal notranslate">{{ account.user.name }}</p>
                  </div>
                  <div class="col-md-6 text-center-sm">
                    <p class="mb-0 text-uppercase font-weight-normal card-title-desc">{{ t('Email') }}</p>
                    <p class="mb-3 font-weight-normal notranslate">{{ account.user.email.address }}</p>
                  </div>
                  <div class="col-md-6 text-center-sm">
                    <p class="mb-0 text-uppercase font-weight-normal card-title-desc">{{ t('Cellphone') }}</p>
                    <p class="mb-3 font-weight-normal notranslate">{{ account.user.cellphone }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>